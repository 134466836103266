type FkModal = {
  name: string;
  isOpen: boolean;
};

export const useWerkgelukModal = (modalName: string) => {
  const isModalOpenFlag = useState("openModals", () => [] as FkModal[]);

  //check if modal exists, if not add it to the list
  if (!isModalOpenFlag.value.find((modal) => modal.name === modalName)) {
    isModalOpenFlag.value.push({ name: modalName, isOpen: false });
  }
  //create toggle function
  const toggle = () => {
    const modal = isModalOpenFlag.value.find(
      (modal) => modal.name === modalName,
    );
    if (modal) {
      modal.isOpen = !modal.isOpen;
    }
  };
  //create open function
  const open = () => {
    const modal = isModalOpenFlag.value.find(
      (modal) => modal.name === modalName,
    );
    if (modal) {
      modal.isOpen = true;
    }
  };
  //create close function
  const close = () => {
    const modal = isModalOpenFlag.value.find(
      (modal) => modal.name === modalName,
    );
    if (modal) {
      modal.isOpen = false;
    }
  };
  //return the functions and the state
  return {
    isOpen: computed(() => {
      const modal = isModalOpenFlag.value.find(
        (modal) => modal.name === modalName,
      );
      return modal ? modal.isOpen : false;
    }),
    toggle,
    open,
    close,
  };
};
